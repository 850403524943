export default {
  references: {
    title: "Spécifications techniques",
    description: "Ci-dessous la spécification technique de ce produit",
  },
  unavailable: "Ce produit n'est pas disponible à l'achat pour le moment.",
  "supplier-listings": {
    form: {
      reserve: "Faire une réservation",
      rfq: "Demander un devis",
      checkout: "Acheter maintenant",
      "sign-up": "Inscrivez-vous ici",
      "sign-in": "Se connecter",
    },
  },
  card: {
    unavailable: "Indisponible",
    "add-to-cart": "Ajouter au panier",
    purchase: "Achat",
    pro: "Pro",
    "product-category": {
      ADMINISTRATION: "Administration",
      DIGITAL_SALES_TOOLS: "Commercialisation",
      HARDWARE: "Matériel",
      LARGE_PRODUCER_ADMINISTRATION: "Grands producteurs",
    },
  },
  preview: {
    "in-stock-ready-to-ship": "En stock et prêt à être utilisée",
    "out-of-stock": "En rupture de stock",
    "product-information": "Informations sur le produit",
    "view-full-details": "Voir les détails",
    unavailable: "Indisponible",
    reviews: "revues",
    "out-of-5-stars": "sur 5 étoiles",
    "product-options": "Options du produit",
    quantity: "Quantité",
    "how-to-use":
      "Voulez-vous en savoir plus sur fonctionnement de cette prestation ?",
    button: "Ajouter au panier",
    refundable: "Remboursable",
  },
}
